.solutions_work {
  padding-top: 80px;
  padding-bottom: 60px;

  .main-page & {
    padding-top: 50px;
    padding-bottom: 0;
  }

  @include respond-to(phone) {
    padding-top: 40px;
    padding-bottom: 30px;

    .main-page & {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }
}
