.header_switcher {
  display: none;
  margin-bottom: 28px;
  justify-content: center;

  &.active {
    display: flex;
  }

  &__inner {
    background-color: #6100ff;
    display: flex;
    padding: 8px;
    gap: 4px;
    border-radius: 12px;

    @include respond-to(phone-large) {
      flex-direction: column;
    }

    &__item {
      position: relative;
      padding: 16px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: 0.2px;
      cursor: pointer;
      color: #ffffff;
      border-radius: 8px;
      overflow: hidden;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #ffffff;
        position: absolute;
        border-radius: 8px;
        top: 0;
        left: 0;
      }

      &.active {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }
}

.first_form {
  display: none;

  &.active {
    display: block;
  }
}

.second_form {
  display: none;

  &.active {
    display: flex;
  }

  &__overlay {
    display: flex;
    align-items: stretch;
    gap: 16px;

    @include respond-to(tablet-small) {
      flex-direction: column;
    }
  }

  &__info {
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    padding: 48px 30px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 8px);
    flex-shrink: 0;

    @include respond-to(tablet-small) {
      width: 100%;
    }

    @include respond-to(phone-large) {
      padding: 24px 16px;
    }

    &__title {
      font-family: 'Montserrat', sans-serif;
      font-size: 28px;
      line-height: 1.15;
      color: #000000;
      text-align: left;
      margin-bottom: 12px;

      span {
        color: #00ae65;
      }
    }

    &__text {
      .modal-wrapper .modal-container & {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #232323;
        text-align: left;
        margin-bottom: 12px;
      }

      @include respond-to(phone-large) {
        .modal-wrapper .modal-container & {
          font-size: 15px;
          margin-bottom: 20px;
        }
      }
    }

    &__advantages {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__item {
        display: flex;
        align-items: center;

        &__img {
          display: flex;
          margin-right: 16px;
        }

        &__text {
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
          color: #232323;
          text-align: left;

          @include respond-to(phone-large) {
            font-size: 15px;
          }
        }
      }
    }
  }

  &__access {
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    padding: 48px 30px 15px;
    width: calc(50% - 8px);
    flex-shrink: 0;

    @include respond-to(tablet-small) {
      width: 100%;
    }

    @include respond-to(phone-large) {
      padding: 24px 16px;
    }

    &__title {
      .modal-wrapper .modal-container & {
        font-family: 'Montserrat', sans-serif;
        font-size: 28px;
        line-height: 1.15;
        color: #000000;
        text-align: left;
        margin-bottom: 12px;

        span {
          color: #dc2d06;
        }
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #232323;
      text-align: left;
      margin-bottom: 12px;

      @include respond-to(phone-large) {
        .modal-wrapper .modal-container & {
          font-size: 15px;
          margin-bottom: 20px;
        }
      }
    }

    .fields {
      display: flex;

      @include respond-to(phone-large) {
        .demonstration_form .form-container & {
          margin-bottom: 0;
        }
      }

      .input {
        margin-bottom: 16px;
      }

      button {
        display: flex;
        align-self: flex-start;
        margin-top: 4px;

        @include respond-to(tablet-small) {
          align-self: center;
        }
      }

      .privacy-policy {
        text-align: left;

        @include respond-to(tablet-small) {
          text-align: center;
        }

        @include respond-to(phone) {
          font-size: 14px;
        }
      }
    }
  }
}
