.tr_footer {
  background-color: #1d232a;
  padding: 80px 0 40px;
  user-select: none;

  @include respond-to(tablet) {
    padding: 60px 0 40px;
  }

  @include respond-to(phone) {
    padding: 40px 0 30px;
  }

  &__inner {
    &__top {
      margin-bottom: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to(tablet-large) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 44px;
      }

      @include respond-to(phone) {
        margin-bottom: 30px;
      }

      &_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(tablet-large) {
          width: 100%;
          margin-bottom: 60px;
          flex-direction: column;
          align-items: center;
        }

        @include respond-to(phone) {
          margin-bottom: 40px;
        }
      }

      &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-to(tablet-large) {
          align-items: center;
        }

        &__phone {
          font-weight: 800;
          font-size: 28px;
          line-height: 34px;
          color: #ffffff;
          margin-bottom: 16px;
          display: block;
          transition: opacity 0.55s ease-out;

          &:hover {
            opacity: 0.6;
          }
        }

        &__emails {
          display: flex;

          @include respond-to(mini) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          &__item {
            &:not(:last-child) {
              margin-right: 40px;

              @include respond-to(phone) {
                margin-right: 30px;
              }

              @include respond-to(mini) {
                margin-right: 0;
                margin-bottom: 12px;
              }
            }

            &__title {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 4px;

              @include respond-to(phone) {
                text-align: center;
              }
            }

            &__link {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #ffffff;
              transition: opacity 0.55s ease-out;

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }

      &__networks {
        &__icon {
          &:not(:last-child) {
            margin-right: 24px;

            @include respond-to(phone) {
              margin-right: 12px;
            }
          }

          svg {
            @include respond-to(mini) {
              width: 40px;
              height: 40px;
            }
          }

          .path_circle {
            transition: fill-opacity 0.25s linear, fill 0.25s linear;
            fill-opacity: 0.15;
          }

          .path_icon {
            transition: fill 0.25s linear;
          }

          &:hover {
            .path_circle {
              fill-opacity: 1;
            }

            .path_icon {
              fill: #1d232a;
            }
          }
        }
      }
    }

    &__bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      padding-top: 32px;

      @include respond-to(phone) {
        padding-top: 20px;
      }

      &__privacy_link {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        a {
          color: #ffffff;
          transition: opacity 0.55s ease-out;

          &:hover {
            opacity: 0.6;
          }
        }
      }

      &__copyright {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);

        a {
          color: #ffffff;
          transition: opacity 0.55s ease-out;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
