.stats_block__title {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;
  color: #181818;
  margin-top: 40px;
  margin-bottom: 32px;

  @media only screen and (max-width: 1024px) {
    font-size: 24px;
  }
}

.stats_block {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px 42px;
  padding: 56px 83px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 40px auto 80px;
  overflow: hidden;

  @include respond-to(tablet-small) {
    padding: 38px 56px;
  }

  @include respond-to(phone) {
    padding: 28px 42px;
    display: flex;
    flex-direction: column;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    opacity: 0.6;
  }

  &::before {
    width: 250px;
    height: 250px;
    background: radial-gradient(circle, rgba(0, 196, 255, 0.5), rgba(0, 196, 255, 0) 70%);
    bottom: 0;
    left: 30%;
    transform: translate(0, 58%);
  }

  &::after {
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(107, 39, 255, 0.5), rgba(107, 39, 255, 0) 70%);
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }

  &__item {
    display: flex;
    align-items: flex-start;

    @include respond-to(micro) {
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      width: 65px;
      height: 64px;
      margin-right: 20px;
      flex-shrink: 0;

      @include respond-to(micro) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;

      &__number {
        color: #5405be;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        line-height: 1.25;
        font-weight: 800;

        @include respond-to(micro) {
          text-align: center;
        }
      }

      &__descr {
        color: #181818;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 1.42;
        font-weight: 500;

        @include respond-to(micro) {
          text-align: center;
        }
      }
    }
  }
}
