.solutions_developed {
  padding-top: 140px;
  padding-bottom: 110px;

  .main-page & {
    padding-top: 120px;
  }

  @include respond-to(tablet) {
    padding-top: 70px;
    padding-bottom: 56px;

    .main-page & {
      padding-top: 60px;
    }
  }

  @include respond-to(phone) {
    margin-bottom: 80px;
  }

  &__inner {
    &__title {
      margin-bottom: 62px;
      font-weight: 800;
      font-size: 50px;
      line-height: 1.08;
      text-align: center;

      @include respond-to(tablet) {
        font-size: 42px;
      }

      @include respond-to(phone) {
        font-size: 28px;
        line-height: 1.08;
        margin-bottom: 40px;
      }
    }

    &__block {
      display: flex;

      @include respond-to(phone) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 360px;

        @include respond-to(phone) {
          width: 100%;
          max-width: 270px;
        }

        &:not(:last-child) {
          margin-right: 40px;

          @include respond-to(tablet) {
            margin-right: 30px;
          }

          @include respond-to(phone) {
            margin-right: 0;
            margin-bottom: 30px;
          }
        }

        &__img {
          margin-bottom: 16px;

          @include respond-to(tablet) {
            margin-bottom: 10px;
          }

          img {
            @include respond-to(phone) {
              width: 100px;
            }
          }
        }

        &__text {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #747474;
          margin: 0;
        }
      }
    }
  }
}
