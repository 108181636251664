.solutions_embed {
  padding-top: 60px;
  padding-bottom: 60px;

  .main-page & {
    padding-top: 120px;
  }

  @include respond-to(phone) {
    padding-top: 30px;
    padding-bottom: 30px;

    .main-page & {
      padding-top: 60px;
    }
  }

  &__inner {
    padding: 0;

    @include respond-to(tablet-large) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__title {
      font-family: 'Montserrat', sans-serif;
      color: #101820;
      text-align: center;
      font-size: 50px;
      font-weight: 800;
      line-height: 1.08;
      margin-bottom: 24px;

      @include respond-to(tablet) {
        font-size: 42px;
      }

      @include respond-to(phone) {
        font-size: 28px;
        margin-bottom: 16px;
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      color: #747474;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 68px;

      @include respond-to(tablet) {
        margin-bottom: 48px;
      }

      @include respond-to(phone) {
        margin-bottom: 36px;
      }
    }

    &__blocks {
      display: flex;
      gap: 24px;

      @include respond-to(tablet-large) {
        flex-direction: column;
        align-items: center;
      }

      @include respond-to(tablet) {
        gap: 20px;
      }

      @include respond-to(phone) {
        gap: 20px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 30px;
        border-radius: 16px;
        border: 1px solid #e0e0e0;
        background: #fff;
        width: calc(100% / 3);

        @include respond-to(tablet-large) {
          width: 100%;
          max-width: 570px;
        }

        @include respond-to(tablet) {
          padding: 36px 30px;
        }

        @include respond-to(phone) {
          padding: 26px 28px;
        }

        &__img {
          margin-bottom: 28px;
        }

        &__title {
          color: #181818;
          font-family: 'Montserrat', sans-serif;
          font-size: 24px;
          font-weight: 800;
          line-height: 1.25;
          margin-bottom: 16px;
          text-align: center;

          @include respond-to(phone) {
            font-size: 20px;
          }
        }

        &__text {
          color: #747474;
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 16px;

          @include respond-to(tablet-large) {
            text-align: center;
          }

          @include respond-to(phone) {
            font-size: 14px;
          }
        }

        &__list {
          @include respond-to(tablet-large) {
            align-self: flex-start;
          }

          &__elem {
            color: #747474;
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            list-style: disc;
            margin-left: 16px;

            @include respond-to(phone) {
              font-size: 14px;
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            &::marker {
              color: #4700bb;
            }
          }
        }
      }
    }
  }
}
