.block_reviews {
  margin-bottom: 80px;

  @include respond-to(tablet) {
    margin-bottom: 60px;
  }

  @include respond-to(phone) {
    margin-bottom: 40px;
  }

  &-container {
    max-width: 1120px;

    @include respond-to(tablet-large) {
      max-width: 100%;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 50px;
      line-height: 1.08;
      font-weight: 800;
      text-align: center;
      margin-bottom: 24px;

      @include respond-to(tablet) {
        font-size: 42px;
      }

      @include respond-to(phone) {
        font-size: 28px;
      }
    }

    &__text {
      color: #747474;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
    }

    &__blocks {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      margin-top: 68px;

      @include respond-to(tablet) {
        flex-direction: column;
        align-items: center;
      }

      @include respond-to(phone) {
        margin-top: 40px;
      }

      &__item {
        padding: 28px 44px 44px 44px;
        border-radius: 16px;
        border: 1px solid #e0e0e0;
        flex-basis: calc(100% - 484px);

        @include respond-to(tablet) {
          flex-basis: auto;
          width: 100%;
          max-width: 676px;
        }

        @include respond-to(phone) {
          padding: 16px 34px 34px 34px;
        }

        &:nth-child(3n + 1) {
          flex-basis: 444px;

          @include respond-to(tablet) {
            flex-basis: auto;
          }
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 28px;
          border-bottom: 1px solid #ececec;

          @include respond-to(phone) {
            padding-bottom: 20px;
            flex-direction: column;
          }

          &__logo {
            margin-right: 20px;
            flex-shrink: 0;

            @include respond-to(phone) {
              display: flex;
              justify-content: center;
              margin-right: 0;
            }
          }

          &__tag {
            background: $gradient-orange-original;
            padding: 4px 20px;
            border-radius: 6px;
            font-weight: 600;
            color: white;
            text-align: center;
            font-size: 12px;
            line-height: 24px;

            @include respond-to(phone) {
              order: -1;
              margin-bottom: 20px;
            }
          }
        }

        &__text {
          color: #181818;
          font-size: 20px;
          font-style: italic;
          font-weight: 500;
          line-height: 1.4;
          padding: 24px 0;

          @include respond-to(phone) {
            font-size: 16px;
            padding: 18px 0;
          }
        }

        &__link {
          display: flex;

          @include respond-to(phone) {
            justify-content: center;
          }

          &__item {
            display: inline-flex;
            text-decoration: none;
            color: $purple;
            font-weight: 600;
            flex-grow: 0;
            transition: linear 250ms;
            opacity: 1;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;

            &:hover {
              opacity: 0.7;
            }

            .arrow {
              transform: rotate(-90deg);
              margin-top: 5px;
              margin-left: 8px;
            }
          }
        }
      }

      &__2 {
        .block_reviews__inner__blocks__item {
          &:nth-child(3) {
            flex-basis: calc(100% - 556px);

            @include respond-to(tablet) {
              flex-basis: auto;
            }
          }

          &:nth-child(4) {
            flex-basis: 516px;

            @include respond-to(tablet) {
              flex-basis: auto;
            }
          }
        }
      }
    }

    &__button {
      margin-top: 48px;

      @include respond-to(tablet) {
        margin-top: 30px;
      }

      @include respond-to(phone) {
        margin-top: 24px;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: 48px;

    &-bullet {
      width: 10px;
      height: 10px;
    }

    &-bullet-active {
      background: #00ae65;
    }
  }
}
