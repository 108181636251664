@use 'sass:math';
@import '../../node_modules/modern-css-reset';
@import './styles/mixins';
@import './styles/media';
@import './scrollAnimate';
@import './styles/blog-articles';

* {
  body {
    position: relative;

    &.disable {
      @include respond-to(tablet-small) {
        overflow: hidden;
      }
    }
  }

  font-family: $font-family;

  ul {
    &.simple {
      li:before {
        content: '';
        display: block;
        position: absolute;
        top: 0.8em;
        left: 0;
        width: 1em;
        height: 1px;
        margin-top: -1px;
        background: currentColor;
      }
    }

    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }
}

header {
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 30px;
  z-index: 10;

  &.fixed,
  .privacy_policy & {
    padding-top: 0;
    position: fixed;

    .topbar-item {
      a {
        color: #181818;

        &:hover {
          opacity: 1;
          color: $purple;

          svg path {
            fill: $purple;
          }
        }

        svg path {
          fill: #181818;
        }
      }

      .logo {
        width: 95px;

        &-image {
          &--green {
            opacity: 1;
          }

          &--white {
            opacity: 0;
          }
        }
      }
    }
  }
}

main {
  overflow: hidden;
}

.footer {
  background: $dark;
  color: $white;
  padding: 40px 0;
  overflow: hidden;

  .footer-links {
    text-align: center;
    margin-bottom: 32px;

    .footer-item {
      a {
        font-weight: 600;
        font-size: 16px;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: $white-60;
        padding-right: 8px;
      }
    }
  }

  .social-links {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 32px;

    &-item {
      flex: 0 0 auto;

      svg {
        path.path_circle {
          opacity: 0.15;
          transition: linear 250ms;
        }

        path.path_icon {
          fill: $white;
        }
      }

      &:hover {
        svg {
          path.path_circle {
            opacity: 1;
          }

          path.path_icon {
            fill: $dark;
          }
        }
      }
    }
  }

  .footer-copyright {
    color: $white-60;
    text-align: center;

    a {
      color: $white;
      transition: opacity 0.55s ease-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.button {
  position: relative;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  color: #ffffff;
  outline: none;
  display: inline-block;
  transition: linear 250ms;
  background-size: 300% 300%;

  &:hover {
    background-position: 100% 100%;
  }

  &--white {
    background-image: $gradient-white;
  }

  &--green {
    background-image: $gradient-green;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        background-position: 0 0;
      }
    }
  }

  &--red {
    background-image: $gradient-red;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        background-position: 0 0;
      }
    }
  }

  &--orange {
    background-image: $gradient-orange;
  }

  &--grey {
    color: $grey;
    background-image: $gradient-grey;

    &.open {
      background: rgba(224, 224, 224, 0.4);
    }

    svg {
      fill: #181818;
    }
  }

  &--border {
    border: 1px solid #e0e0e0;

    &:hover {
      background-color: #e0e0e0;
      color: #1d232a;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/icons/button_spinner.svg') 50% 50% transparent no-repeat;
    background-size: auto 55px;
    z-index: -5;
    opacity: 0;
    transition: opacity linear 250ms;
  }

  &--loading {
    color: transparent;

    &::after {
      z-index: 5;
      opacity: 1;
    }
  }

  &-switch-lang {
    padding: 16px;
    display: flex;

    + ul {
      visibility: hidden;
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translate(-50%, 0);
      background: white;
      box-shadow: 0 0.125em 0.875em 0 rgb(25 46 84 / 15%);
      padding: 10px 0;
      border-radius: 0.5em;

      li {
        display: block;

        a {
          display: flex;
          align-items: center;
          padding: 12px 16px;

          &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
              linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
            color: white;
          }

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    &.open {
      background: rgba(224, 224, 224, 0.4);

      + ul {
        visibility: visible;
      }
    }

    a {
      display: inline-flex;
      align-items: center;

      svg:first-child {
        margin-right: 10px;
      }
    }
  }

  &--more-pg {
    padding: 18px 46px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-weight: 800;
  font-size: 50px;
  line-height: 1.08;
}

h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.1875;
}

h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;
}

h4 {
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
}

p {
  font-weight: 600;

  &.normal {
    font-weight: 400;
  }
}

.cookie-modal {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e1e4ea;
  display: none;
  flex-direction: column;
  padding: 20px;
  max-width: 580px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgb(25 46 84 / 10%);
  z-index: 100;

  &__title {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .button {
    margin: 0 auto;
  }

  p {
    text-align: start;
    margin-bottom: 16px;
    -webkit-text-size-adjust: 100%;
  }

  a {
    color: $green;
  }
}

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  visibility: hidden;
  z-index: 1000;
  top: 0;
  transition: background-color 0.4s ease;

  &:target {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    background: rgba(0, 0, 0, 0.6);

    &.privacy-modal {
      .modal-window {
        transform: scale(1);
      }
    }
  }

  &.privacy-modal {
    a {
      color: $purple;
    }

    .modal {
      &-window {
        transition: transform 0.4s ease;
        transform: scale(0.8);
        max-width: 65%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        background: $white;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        max-height: 98%;
      }

      &-header {
        z-index: 10;

        .modal-close {
          position: absolute;
          cursor: pointer;
          margin-right: 20px;
          margin-top: 20px;
          top: 0;
          right: 0;
          color: #8692a7;
          transition: color 0.15s ease-in-out;

          &:hover {
            color: #677186;
          }
        }
      }

      &-container {
        overflow: auto;
        text-align: left;
        padding: 48px 40px;
        background: transparent;
        position: relative;
        max-width: 100%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: none;
        max-height: unset;

        ul {
          & > li {
            list-style: outside;
            margin-left: 18px;
          }
        }

        ol {
          & > li {
            margin-bottom: 20px;
          }
        }

        h2 {
          margin-bottom: 20px;
          text-align: center;
        }

        &__privacy_title {
          font-weight: 800;
          font-size: 32px;
          line-height: 1.1875;
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
  }

  .modal-container {
    text-align: center;
    max-width: 480px;
    position: fixed;
    padding: 48px 40px;
    background: $white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%) scale(0.85);
    opacity: 0;
    transition: transform 0.4s ease;
    max-height: 98%;
    overflow-y: auto;
    overflow-x: hidden;

    &__title {
      margin-bottom: 20px;
      @include h3();
    }

    p {
      margin-bottom: 32px;
    }

    .button {
      width: 160px;
    }

    &--xl {
      max-width: unset;
      width: 70%;
      max-width: 800px;
    }

    &--xxl {
      max-width: unset;
      width: 90%;
      max-width: 980px;

      @include respond-to(tablet-small) {
        width: 70%;
        max-width: 800px;
      }

      @include respond-to(phone-large) {
        padding: 48px 16px 24px;
      }
    }
  }

  &.visible {
    .modal-container {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
}

.slogan {
  text-align: center;
  margin-bottom: 80px;

  span {
    color: $purple;
  }
}

.input {
  @include input-text('input');
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.textarea {
  @include input-text('textarea');
}

.grey {
  color: $grey;
}

.logo {
  position: relative;
  width: 110px;
  transition: linear 350ms;
  transition: linear 250ms;
  opacity: 1;

  &-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    opacity: 1;
    transition: linear 250ms;

    &--green {
      opacity: 0;
    }
  }

  &:hover {
    opacity: 0.7;
  }
}

.nav {
  &.transparent {
    background-color: transparent;
  }

  &.colored,
  .privacy_policy & {
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
  }
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  height: $topbar-height;
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;

  &-button {
    margin-left: auto;
    margin-right: 16px;

    @include respond-to(tablet-large) {
      margin-right: 8px;
    }

    @include respond-to(tablet-small) {
      display: none;
    }

    &_mobile {
      display: none;

      @include respond-to(menu) {
        display: inline-flex;
        z-index: 2;
        margin-top: 60px;
      }
    }
  }

  &-links {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
  }

  &-item {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;

    &:first-child {
      margin-right: 0;
      flex-shrink: 0;
    }

    & + & {
      margin-left: 40px;
    }

    a {
      text-decoration: none;
      color: white;
      opacity: 1;
      transition: linear 250ms;
      cursor: pointer;

      &.topbar_submenu {
        opacity: 0.7;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &_submenu {
      .submenu_block__links__item__title {
        color: black;

        &_all {
          color: $purple;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  .case-arrow {
    display: inline;
    margin: 0 0 2px 10px;
  }
}

.arrow {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' width='12' height='7' viewBox='0 0 12 7' %3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.70711 0.292893C1.31658 -0.0976315 0.683417 -0.0976316 0.292893 0.292893C-0.0976305 0.683417 -0.0976306 1.31658 0.292893 1.70711L5.29289 6.70711C5.68342 7.09763 6.31658 7.09763 6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L6 4.58579L1.70711 0.292893Z' /%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.arrow--white {
  -webkit-filter: grayscale(100%) brightness(5);
  filter: grayscale(100%) brightness(5);
}

.arrow--purple {
  filter: hue-rotate(300deg) saturate(0.64);
  -webkit-filter: hue-rotate(263deg) saturate(0.64);
}

.arrow--gray {
  -webkit-filter: grayscale(100%) brightness(5) opacity(0.6);
  filter: grayscale(100%) brightness(5) opacity(0.6);
  transform: rotate(-90deg);
}

.arrow-long {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8694 25.2487H33.0014C33.7003 25.2487 34.25 24.6664 34.25 23.9663C34.25 23.2613 33.6981 22.6839 33.0014 22.6839H17.9347L21.566 18.935C22.0465 18.4389 22.0468 17.6302 21.5627 17.1305C21.0716 16.6234 20.2799 16.624 19.7926 17.1271L14.1293 22.9739C13.9436 23.1656 13.8301 23.4039 13.7879 23.6512C13.7631 23.7523 13.75 23.8579 13.75 23.9663C13.75 24.0479 13.7574 24.1278 13.7715 24.2055C13.7893 24.5031 13.9078 24.7975 14.1293 25.0261L19.7926 30.8729C20.2799 31.376 21.0716 31.3766 21.5627 30.8695C22.0468 30.3698 22.0465 29.5611 21.566 29.065L17.8694 25.2487Z' fill='red' stroke='red' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
}

.submenu_block {
  display: none;
  background-color: white;
  border-radius: 16px;
  position: fixed;
  top: 90px;
  z-index: 20;
  border: 1px solid #e0e0e0;

  @include respond-to(menu) {
    width: 100%;
    max-width: 312px;
    padding: 0 26px;
    border-radius: 20px;
    list-style-type: none;
    text-align: center;
    overflow: hidden;
    position: relative;
    max-height: 0;
    transition: max-height 0.55s ease-out;
    display: block;
    top: 0;
    border: none;
  }

  &__links {
    margin: 16px 28px;

    @include respond-to(menu) {
      margin: 0;
    }

    &__item {
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;

      @include respond-to(menu) {
        height: auto;
        justify-content: center;
      }

      a:hover {
        color: #4700bb;
        opacity: 1;
      }

      &:first-child {
        @include respond-to(menu) {
          margin-top: 26px;
        }
      }

      &:last-child {
        @include respond-to(menu) {
          margin-bottom: 26px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;

        @include respond-to(menu) {
          margin-bottom: 26px;
          border-bottom: none;
        }
      }

      &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.2px;
        text-align: left;

        @include respond-to(menu) {
          opacity: 0.6;
          text-align: center;
        }

        &_all {
          @include respond-to(menu) {
            opacity: 1;
          }
        }

        &_all {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: $purple;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .arrow {
            transform: rotate(-90deg);
            margin-left: 8px;
          }
        }
      }

      &_all {
        text-decoration: none;
        color: $purple;
        font-weight: 600;
        flex-grow: 0;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__arrow {
    display: inline;
    margin: 0 0 2px 10px;
  }
}

// SECTIONS

.welcome_section {
  background: linear-gradient(107.31deg, #4700bb 0%, #951bcc 99.29%);
  color: white;
  padding-bottom: 60px;
  height: auto;

  &:not(.cases) {
    padding-bottom: 100px;

    @include respond-to(tablet-mini) {
      padding-bottom: 60px;
    }

    .welcome_section-container {
      align-items: center;
    }

    .welcome_section-description {
      font-size: 20px;
      line-height: 1.5;
    }

    .welcome_section-info {
      margin-bottom: 0;
    }
  }

  &-container {
    padding-top: $topbar-height + 60px;
    display: flex;
    justify-content: space-between;
  }

  &-info {
    display: flex;
    flex-direction: column;
    flex-basis: 520px;
    flex-shrink: 0;
    margin-bottom: 30px;
    padding-top: 60px;
  }

  &-app {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: rgba(#ffffff, 0.5);
    margin-top: 60px;
    gap: 8px;

    p.normal {
      font-size: 14px;

      &--rf-registry {
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
      }

      &--rf-registry {
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
      }

      &--downloads {
        font-weight: 600;
        color: rgba(255, 255, 255, 0.6);
        padding-right: 15px;
      }
    }

    a {
      display: flex;

      &:hover {
        svg {
          g {
            opacity: 0.9;
          }
        }
      }
    }

    svg {
      margin-right: 8px;

      g {
        transition: linear 150ms;
      }
    }

    img {
      margin-right: 8px;
    }

    &-downloads {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      div {
        display: inline-flex;
      }
    }

    &-registry {
      display: flex;
      align-items: center;
      margin-top: 4px;

      picture {
        flex-shrink: 0;
      }
    }
  }

  &-title {
    margin-bottom: 24px;
    font-size: 44px;
  }

  &-description {
    color: rgba(#ffffff, 0.5);
    font-weight: 400;
    margin-bottom: 48px;
  }

  &-links {
    margin-right: auto;
    display: flex;

    a {
      display: block;

      &:first-child {
        margin-right: 16px;
      }
    }
  }

  &-img {
    position: relative;
    text-align: right;
    flex-shrink: 0;
  }
}

.brand_section {
  padding-top: 80px;
  padding-bottom: 36px;

  &-container {
    .brand-list {
      display: flex;
      justify-content: space-between;
    }

    .brand-button {
      margin-top: 35px;
      text-align: center;
    }
  }
}

.section {
  padding-top: $container-indent;
  padding-bottom: $container-indent;

  &-container {
    max-width: $container-width;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: auto;
  }
}

.features_section {
  @include section_default_paddings();

  &-container {
    display: flex;
    justify-content: space-between;
  }

  .feature {
    border: 1px solid #e0e0e0;
    max-width: 260px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h3 {
      min-height: 52px;
      margin-bottom: 16px;
      @include h4();
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
      line-height: 1.25;
    }

    &-description {
      font-weight: 400;
      margin-bottom: 20px;
      min-height: 120px;

      .bold {
        font-weight: 600;
      }
    }

    &-info {
      padding: 20px 20px 80px 20px;
      position: relative;
      flex-grow: 1;
    }

    &-link {
      position: absolute;
      bottom: 24px;
      display: flex;
      margin-top: auto;
      text-decoration: none;
      color: $purple;
      font-weight: 600;
      flex-grow: 0;
      transition: linear 250ms;
      opacity: 1;

      &:hover {
        opacity: 0.7;
      }
    }

    .arrow {
      transform: rotate(-90deg);
      margin-top: 5px;
      margin-left: 8px;
    }
  }
}

.possibilities_section {
  @include section_default_paddings();

  &-container {
    display: flex;

    .posibilities_image {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      position: relative;
      z-index: -1;
      order: 2;

      .mockups-container {
        position: relative;
        width: 75%;
        height: 90%;

        .posibilities-mock {
          @include mockup-no-shadow();
        }

        .posibilities-screen {
          @include screen-in-mockup();

          &--laptop {
            width: 600px;
            height: 660px;
            max-width: initial;
            right: -140px;
          }
        }
      }
    }
  }
}

.work_section {
  @include section_default_paddings();
}

.solution_section {
  @include section_default_paddings();

  &-container {
    &__title {
      @include h1();
    }

    .solution-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &--item {
        flex: 0 0 calc(33% - (40px / 3));
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .device-img,
        picture {
          margin-top: auto;
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        img {
          margin-bottom: 28px;
        }

        &__title {
          margin-bottom: 16px;
          text-align: center;
          min-height: 78px;
          @include h4();
        }

        p {
          text-align: center;
          margin-bottom: 64px;
        }
      }
    }

    .solution-devices {
      display: flex;
      justify-content: space-between;

      li {
        align-self: center;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .slogan {
    margin-bottom: 72px;
    text-align: center;

    span {
      color: $purple;
    }
  }
}

.get_offer {
  @include section_default_paddings();

  .section-container {
    position: relative;
    z-index: 1;
  }

  &-container {
    background: $dark;
    position: relative;
    padding: 56px 0;
    text-align: center;

    &__title {
      color: $white;
      padding-bottom: 36px;
      @include h4();
    }
  }

  &-image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40%;
    background-repeat: no-repeat;
    background-size: contain;

    &.image--left {
      left: 0;
      background-image: url(./assets/get-offer/cta_bg-left@2x.png);
      background-position: left center;

      .webp & {
        background-image: url(./assets/get-offer/cta_bg-left@2x.webp);
      }
    }

    &.image--right {
      right: 0;
      background-image: url(./assets/get-offer/cta_bg-right@2x.png);
      background-position: right center;

      .webp & {
        background-image: url(./assets/get-offer/cta_bg-right@2x.webp);
      }
    }
  }

  &.cases & {
    &-container {
      h4,
      &__title {
        width: 70%;
        margin: 0 auto;
      }
    }

    &-image {
      opacity: 0.3;
      width: 50%;
    }
  }
}

.information_section {
  @include section_default_paddings();
}

.community_section {
  @include section_default_paddings();
}

.security_section {
  @include section_default_paddings();
}

.demonstration_section {
  background: linear-gradient(303.52deg, #951bcc 21.76%, #4700bb 85.87%);
  padding-top: 80px;
  padding-bottom: 90px;
  overflow: hidden;

  &-container {
    h1 {
      color: $white;
      margin-bottom: 66px;
      text-align: center;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 50px;
    line-height: 1.08;
    color: $white;
    margin-bottom: 66px;
    text-align: center;
  }
}

.team_section {
  @include section_default_paddings();

  &-container {
    &__title {
      @include h1();
    }

    .slogan {
      margin-bottom: 24px;
    }

    .section-description {
      margin-bottom: 70px;

      a {
        color: #4700bb;
        transition: opacity linear 250ms;
        text-decoration: underline;

        &:hover {
          opacity: 0.7;
          text-decoration: none;
        }
      }
    }

    p {
      text-align: center;
    }
  }

  .skills-list {
    display: flex;
    justify-content: space-between;
    margin-left: -14px;
    margin-right: -14px;

    .item {
      display: flex;
      flex-direction: column;
      width: 25%;

      &:nth-child(2) .info {
        border-left: 1px solid rgba(196, 196, 196, 0.5);
      }

      &:nth-child(3) .info {
        border-left: 1px solid rgba(196, 196, 196, 0.5);
        border-right: 1px solid rgba(196, 196, 196, 0.5);
      }

      .info {
        padding: 0 10px;

        .mark {
          text-align: center;
          background: linear-gradient(341.43deg, #4700bb 10.93%, #951bcc 97.49%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 60px;
          line-height: 72px;
          font-weight: 800;
        }
      }
    }
  }
}

.book_section {
  @include section_default_paddings();
  padding-bottom: 0;

  &-container {
    background: $dark;
    display: flex;
    height: 740px;
  }

  &-img {
    width: 50%;
    background-image: url(./assets/img_book@2x.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .webp & {
      background-image: url(./assets/img_book@2x.webp);
    }
  }

  &-form {
    padding-left: 60px;
    padding-top: 100px;
    max-width: 560px;

    .input {
      margin-bottom: 56px;
    }

    .button {
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 16px;
      color: white;
      @include h2();
    }

    p {
      margin-bottom: 48px;
      color: $white-60;
    }

    .privacy-policy {
      a {
        color: white;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.branding_section {
  padding: 80px 0;
}

.blog_section {
  padding: 80px 0;

  h1 {
    text-align: center;
    margin-bottom: 86px;
  }
}

.review_section {
  @include section_default_paddings();

  &__title {
    @include h1();
    text-align: center;
    margin-bottom: 66px;
  }

  .review-swiper {
    min-height: 470px;
    max-width: 960px;
  }

  .section-container {
    position: relative;
  }

  @include swiper();
}

// END SECTIONS

// CARDS

.card {
  border: 1px solid #e0e0e0;
  border-radius: 16px;

  &--dark {
    background: $dark;
    color: white;
  }

  &--colorful {
    background: linear-gradient(303.52deg, #951bcc 21.76%, #4700bb 85.87%);
    color: white;
  }

  &--actionable {
    transition: linear 250ms;
    position: relative;
    overflow: hidden;
    z-index: 0;

    &::after {
      content: '';
      background: linear-gradient(341.43deg, #4700bb 10.93%, #951bcc 97.49%);
      position: absolute;
      width: 30px;
      height: 30px;
      right: -30px;
      bottom: -30px;
      border-radius: 30px;
      transition: linear 250ms;
      z-index: -1;
    }

    svg {
      path.path-circle,
      path.path-icon {
        transition: linear 250ms;

        &--green {
          fill: #00c180;
        }

        &--red {
          fill: #ff6838;
        }

        &--yellow {
          fill: #ffcb45;
        }

        &--blue {
          fill: #0087fe;
        }
      }
    }

    &:hover,
    &.active {
      background-position: 100% 100%;
      cursor: pointer;

      .information-card__title,
      p {
        color: white;
      }

      &::after {
        @include card_after_scale(30);
      }

      svg {
        path.path-circle,
        path.path-icon {
          fill: $white;
        }
      }
    }

    &:hover {
      box-shadow: 0px 18px 34px rgba(71, 0, 187, 0.25);
    }
  }
}

.possibilities_cards {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.possibility_card {
  @include vertical_card;
}

.work_card {
  @include horizontal_card;
  align-items: center;

  .card-info {
    flex: 1;
  }
}

.information_cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.information-card {
  @include vertical_card;

  &__title {
    text-align: center;
    margin-bottom: 12px;
    @include h4();
  }

  &:hover,
  &.active {
    cursor: default;

    .grey {
      color: $white-60;
    }
  }
}

.community_card {
  display: flex;

  .card-info {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px;

    &__title {
      margin-bottom: 16px;
      @include h2();
    }

    p {
      &:nth-child(2) {
        margin-bottom: 16px;
      }
    }
  }

  &-img {
    flex: 1;
    width: 100%;
    height: auto;
    background: linear-gradient(234.08deg, #d6e0eb 15.34%, #eef4fa 85.4%);
    position: relative;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;

    img {
      bottom: 0;
    }
  }
}

.security_card {
  @include horizontal_card;

  p {
    color: $white-60;
  }
}

.phone_card {
  padding-top: 35px;
  padding-bottom: 18px;
  width: 100%;
  background: transparent;

  .phone,
  .email {
    margin-bottom: 24px;
    text-align: center;
    margin-top: 8px;
    font-weight: 800;
    font-size: 50px;
    line-height: 1.08;
    color: #ffffff;

    &:nth-child(3) {
      margin-bottom: 0;
    }

    a {
      transition: linear 250ms;
      opacity: 1;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  p {
    color: $white;
    text-align: center;

    a {
      color: $white;
    }
  }
}

.branding-card {
  display: flex;
  position: relative;
  padding: 60px;
  padding-right: 0;
  align-items: center;
  height: 480px;
  background: linear-gradient(303.52deg, #951bcc 21.76%, #4700bb 85.87%);

  .branding-content {
    flex: 1 1 50%;

    &__title {
      @include h2();
      color: white;
      margin-bottom: 16px;
    }

    p {
      color: $white-60;
    }

    .branding-buttons {
      margin-top: 48px;
      display: flex;
      gap: 24px;

      .select-circle {
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 2px solid $white;
        box-sizing: border-box;
        border-radius: 50%;
        transition: linear 250ms;
        transform: scale(1);

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-image: url(./assets/icons/ic24_check.svg);
          background-repeat: no-repeat;
          background-size: 70%;
          background-position: center;
          opacity: 0;
          transition: linear 250ms;
        }

        &--blue {
          background: linear-gradient(107.31deg, #1f86ff 0%, #4757cc 99.29%);
        }

        &--red {
          background: linear-gradient(107.31deg, #772ef2 20.17%, #ea7f3e 99.29%);
        }

        &--green {
          background: linear-gradient(107.31deg, #2af079 0%, #b8e634 99.29%);
        }

        &--orange {
          background: linear-gradient(107.31deg, #ff912b 0%, #e70000 99.29%);
        }

        &.active {
          transform: scale(1.5);

          &::before {
            opacity: 1;
          }
        }

        &:hover {
          transform: scale(1.5);
        }
      }
    }
  }

  .branding-img {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;

    .branding-mock-container {
      position: relative;
      width: 75%;
      height: 590px;

      .branding-mock {
        @include mockup-no-shadow();
        // filter отключен из-за некорректного тображения на safari
        filter: none;
      }

      .branding-screen {
        @include screen-in-mockup();

        &--laptop {
          width: 560px;
          max-width: initial;
          right: -66px;
          top: 0;
          bottom: 0;
          margin: auto;
          object-fit: none;
          object-position: left;
        }

        &--phones {
          width: 550px;
          max-width: initial;
          right: -90px;
          top: 0;
          bottom: 0;
          margin: auto;
          object-fit: none;
          object-position: left;
        }
      }
    }
  }
}

.blog_list {
  display: flex;
  justify-content: space-between;

  .blog-card {
    &:not(:last-child) {
      margin-right: 40px;
    }

    display: flex;
    flex-direction: column;
    flex: 1 1;

    .blog-img {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      min-height: 156px;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:nth-child(1) {
      .blog-img {
        background-image: url('./assets/img_blog-01@2x.png');
      }
    }

    &:nth-child(2) {
      .blog-img {
        background-image: url('./assets/img_blog-02@2x.png');
      }
    }

    &:nth-child(3) {
      .blog-img {
        background-image: url('./assets/img_blog-03@2x.png');
      }
    }

    .blog-info {
      padding: 24px;
      flex: 1;

      h4 {
        font-size: 14px;
        margin-bottom: 12px;
        color: rgba(71, 0, 187, 1);
      }

      p {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}

.swiper-slide {
  .review-card {
    display: flex;
    min-height: 500px;

    .review-img {
      min-width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      padding: 36px 28px;
      color: white;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
      }

      .review-person__title {
        @include h4();
        font-size: 24px;
        margin-bottom: 8px;
      }

      p {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .review-info {
      display: flex;
      flex-direction: column;

      .header {
        padding-top: 10px;
        padding-left: 40px;
        padding-right: 25px;
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .review-title {
        background: $gradient-orange-original;
        padding: 8px 12px;
        border-radius: 10px;
        font-weight: 600;
        color: white;
      }

      .content {
        margin-top: auto;
        margin-bottom: auto;
        padding: 0 44px 50px 44px;
        font-style: italic;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        color: #181818;

        &::before {
          content: url('./assets/qoute.png');
          margin-bottom: 16px;

          .webp & {
            content: url('./assets/qoute.webp');
          }
        }
      }
    }
  }

  .review-img {
    background: linear-gradient(191.49deg, rgba(149, 27, 204, 0) 48.43%, rgba(71, 0, 187, 0.8) 89%),
      linear-gradient(157.28deg, rgba(149, 27, 204, 0) 50.69%, rgba(149, 27, 204, 0.6) 93.66%);
  }
}

// END CARDS

.styles {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .style-block {
    display: flex;
    flex: 1 1 300px;
    flex-direction: column;

    img {
      margin-bottom: 44px;
    }

    h3 {
      text-align: center;
      margin-bottom: 44px;
    }

    ul {
      &.wrong li {
        margin-bottom: 34px;
        display: inline-flex;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: url(./assets/icons/ic36_cross.svg);
          display: block;
          margin-right: 24px;
        }
      }

      &.correct li {
        margin-bottom: 34px;
        display: inline-flex;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: url(./assets/icons/ic36_check.svg);
          display: block;
          margin-right: 24px;
        }
      }
    }
  }
}

.demonstration_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;
  position: relative;

  .final-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: scale(0.8);

    img {
      width: 108px;
      margin-bottom: 48px;
    }

    &__title {
      margin-bottom: 16px;
      font-weight: 800;
      font-size: 32px;
      line-height: 1.1875;
    }

    p {
      color: $white-60;
    }
  }

  &.success {
    .form-container {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8);
    }

    .final-screen {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  .form-container {
    transition: opacity 1s ease-out, transform 1s ease-in-out;
    opacity: 1;
    transform: scale(1);
    text-align: center;

    .fields {
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;

      &_contacts {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 20px;

        .input {
          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }

    button {
      align-self: center;
      margin-bottom: 20px;
    }

    .privacy-policy {
      text-align: center;
      color: $white-60;

      a {
        color: $white;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &--modal {
    margin-bottom: 0;

    .demonstration_section__title {
      color: $black-100;
      font-size: 40px;
      margin-bottom: 20px;
    }

    .final-screen {
      &__title {
        color: $black-100;
      }

      p {
        color: $grey;
      }
    }
  }
}

// CASES PAGE
.text-container {
  padding: 40px 60px 50px;
  margin: auto;
  max-width: 880px;

  h2 {
    margin-bottom: 27px;
    line-height: 40px;
  }
}

.title-container {
  margin: auto;
  max-width: 880px;
}

.bordered {
  border: 1px solid #e0e0e0;
  border-radius: 16px;
}

.welcome_section.cases,
.description-section,
.client-section,
.goals-section,
.decision-section,
.review-section-cases,
.results-section,
.plans-section,
.diagram-section,
.employee-feedback {
  p {
    margin-top: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    span.bold {
      font-weight: 600;
    }
  }

  ul li {
    list-style: disc;
    margin-left: 15px;
  }

  ol {
    padding-left: 10px;

    li {
      margin-left: 15px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .bold {
        font-weight: 600;
      }
    }
  }

  .inner_list {
    margin-top: 20px;
    padding-left: 10px;

    li {
      margin-left: 15px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .bold {
        font-weight: 600;
      }
    }
  }

  a {
    &.case_link {
      color: #4700bb;
      transition: 0.25s linear;
      font-weight: 500;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.video-section {
  position: relative;

  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #play_button {
    transform: none;
    top: 0;
  }
}

.welcome_section {
  &.cases {
    margin-bottom: 230px;
  }

  .arrow {
    transform: rotate(90deg);
    margin-top: 4px;
    margin-right: 16px;
  }

  .back-link {
    display: flex;
    position: absolute;
    left: 0;
    height: 30px;
    font-weight: 600;
    // Для hover значения transition переприсваеваются из data-animate-delay
    &:hover {
      opacity: 0.7;
    }
  }

  .cases &-container {
    padding-top: $topbar-height + 51px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .cases &-info {
    display: flex;
    flex-direction: column;
    flex-basis: 760px;
    margin-bottom: 30px;
    padding-top: 60px;
  }

  .cases &-title {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .cases &-description {
    margin-bottom: 0;
  }

  .cases &-img {
    width: 100%;
    height: 200px;
    flex-basis: 760px;
    justify-content: center;
    display: flex;
    max-width: none;

    picture {
      position: absolute;
    }
  }
}

.client-section,
.decision-section,
.results-section {
  padding: 40px 0;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;

    h2 {
      margin: 0;
    }
  }
}

.diagram-section {
  padding: 40px 0 0;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;

    h2 {
      margin: 0;
    }
  }

  .line {
    display: flex;
    align-items: center;

    @include respond-to(phone) {
      flex-direction: column;
    }

    .left {
      margin-right: 40px;
      max-width: 375px;
      width: 100%;

      @include respond-to(phone) {
        margin-right: 0;
        margin-bottom: 40px;
      }

      .diagram_description {
        margin-top: 28px;

        div {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          span {
            &.circle {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 12px;

              &.circle1 {
                background-color: #2fab80;
              }

              &.circle2 {
                background-color: #82cdb3;
              }

              &.circle3 {
                background-color: #ef8954;
              }

              &.circle4 {
                background-color: #63cdd4;
              }

              &.circle5 {
                background-color: #f4b75c;
              }
            }
          }
        }
      }
    }
  }
}

.employee-feedback {
  &__inner {
    background: linear-gradient(107.31deg, #4700bb 0%, #951bcc 99.29%);
    border-radius: 16px;
    overflow: hidden;

    &__top {
      display: flex;
      align-items: center;

      &__image {
        margin-right: 24px;
      }

      &__text {
        h4 {
          font-family: 'Montserrat', sans-serif;
          font-size: 24px;
          font-weight: 800;
          line-height: 1.25;
          color: #ffffff;
          margin-bottom: 8px;
        }

        span {
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #ffffff;
        }
      }
    }

    &__bottom {
      &__img {
        margin: 30px 0;
      }

      &__text {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #ffffff;
      }
    }
  }
}

.review-section-cases {
  padding: 40px 0;

  .text-container {
    padding: 0;
    display: flex;
    min-height: 420px;
    overflow: hidden;
  }

  &_person {
    padding: 32px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f6f9;

    .name {
      margin-top: 24px;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }

    .position {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 16px;
      object-fit: cover;
    }
  }

  &_description {
    padding: 50px 60px 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: italic;

    .quotes {
      margin-bottom: 16px;
    }
  }
}

.results-section {
  .img-block {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      object-fit: cover;
      width: 380px;
      height: 570px;
    }
  }
}

.other-cases-section {
  padding: 80px 0;

  &-container {
    width: 880px;
    margin: auto;
  }

  &__title {
    padding-left: 60px;
    margin-bottom: 43px;
    @include h2();
  }

  .other-cases-swiper {
    min-height: 300px;
    max-width: 960px;
  }

  .swiper-slide {
    .other-cases-card {
      display: flex;
      min-height: 312px;
      box-sizing: content-box;
      border-radius: 16px;
      overflow: hidden;
      position: relative;

      .other-cases-img {
        min-width: 32%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &--eol {
          background-image: url('./assets/cases/img-cases-slider_eol.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_eol.webp');
          }
        }

        &--ranepa {
          background-image: url('./assets/cases/img-cases-slider_ranepa.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_ranepa.webp');
          }
        }

        &--sber {
          background-image: url('./assets/cases/img-cases-slider_sber.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_sber.webp');
          }
        }

        &--rzd_cu {
          background-image: url('./assets/cases/img-cases-slider_RZhD_cu.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_RZhD_cu.webp');
          }
        }

        &--rtl {
          background-image: url('./assets/cases/img-cases-slider_RTL.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_RTL.webp');
          }
        }

        &--gpn {
          background-image: url('./assets/cases/img-cases-slider_GPN.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_GPN.webp');
          }
        }

        &--gals {
          background-image: url('./assets/cases/img-cases-slider_GALS.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_GALS.webp');
          }
        }

        &--rzd {
          background-image: url('./assets/cases/img-cases-slider_RZhD.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_RZhD.webp');
          }
        }

        &--crafter {
          background-image: url('./assets/cases/img-cases-slider_crafter.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_crafter.webp');
          }
        }

        &--ayax {
          background-image: url('./assets/cases/img-cases-slider_ayax.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_ayax.webp');
          }
        }

        &--reso {
          background-image: url('./assets/cases/img-cases-slider_peco.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_peco.webp');
          }
        }

        &--sibur {
          background-image: url('./assets/cases/img-cases-slider_sibur.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_sibur.webp');
          }
        }

        &--rml-journey {
          background-image: url('./assets/cases/img-cases-slider_rmlJourney.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_rmlJourney.webp');
          }
        }

        &--cafestore {
          background-image: url('./assets/cases/img-cases-slider_cafestore.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_cafestore.webp');
          }
        }

        &--bspb {
          background-image: url('./assets/cases/img-cases-slider_bspb.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_bspb.webp');
          }
        }

        &--sm-clinic {
          background-image: url('./assets/cases/img-cases-slider_sm-clinic.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_sm-clinic.webp');
          }
        }

        &--avatariya {
          background-image: url('./assets/cases/img-cases-slider_avataria.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_avataria.webp');
          }
        }

        &--pgufksit {
          background-image: url('./assets/cases/img-cases-slider_pgufksit.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider_pgufksit.webp');
          }
        }

        &--movement-first {
          background-image: url('./assets/cases/img-cases-slider-movement-first.png');

          .webp & {
            background-image: url('./assets/cases/img-cases-slider-movement-first.webp');
          }
        }
      }

      .other-cases-info {
        background: linear-gradient(303.52deg, #951bcc 21.76%, #4700bb 85.87%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 60px;
        color: $white-60;

        &__title {
          color: white;
          padding-bottom: 16px;
          @include h3();
        }

        &__text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          line-clamp: 4;
          box-orient: vertical;
        }
      }

      & > a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  @include swiper();
}

.plans-section {
  margin-bottom: 40px;
}

.main {
  .topbar {
    &-item:not(:first-child) {
      margin-left: 30px;
    }
  }
}

.form-modal {
  h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .form-container {
    .privacy-policy {
      color: $grey;
      margin-bottom: 0;

      a {
        color: $black-100;
      }
    }
  }

  .modal-container {
    display: block;

    .button {
      width: auto;
    }
  }

  .modal-close {
    position: absolute;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 20px;
    top: 0;
    right: 0;
    color: #8692a7;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: #677186;
    }
  }
}

.video-modal {
  iframe,
  .modal-close-overlay {
    width: 80vw;
    height: 45vw;
  }

  .modal-close {
    position: absolute;
    cursor: pointer;
    margin-right: -40px;
    top: 0;
    right: 0;
    color: #ffffff;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &-overlay {
      position: absolute;
      z-index: -1;
    }
  }
}

#play_button {
  cursor: pointer;
  position: absolute;
  z-index: 5;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  inset: 0;
  margin: auto;
  transform: translateY(-110px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 20px 4px rgba(0, 0, 0, 0.3);

  &.adaptation__welcome__inner__top__img__button {
    transform: translate3d(-116px, -64px, 0);

    @include respond-to(tablet-large) {
      transform: translate3d(-90px, -64px, 0);
    }

    @include respond-to(tablet-small) {
      transform: translate3d(-70px, -64px, 0);
    }

    @include respond-to(tablet-mini) {
      transform: translate3d(-124px, -52px, 0);
    }

    @include respond-to(phone) {
      transform: translate3d(0, -50px, 0);
    }

    @include respond-to(mini) {
      transform: none;
    }
  }

  @include respond-to(mini) {
    width: 12vw;
    height: 12vw;
    transform: translateY(0);
    top: -40%;
  }

  svg {
    display: block;
    position: relative;
    left: 2px;
    z-index: 3;

    @include respond-to(mini) {
      width: 26%;
      left: 4%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;

    @include respond-to(mini) {
      width: 13.3vw;
      height: 13.3vw;
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    display: block;
    width: 72px;
    height: 72px;
    background: linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
    border-radius: 50%;
    transition: transform 0.15s linear;

    @include respond-to(mini) {
      width: 12vw;
      height: 12vw;
    }
  }

  &:hover::after {
    transform: scale(1.05);
  }
}

header .marquiz__button {
  @include marquiz_button;
}

header .topbar-button.marquiz__container {
  display: flex;
  justify-content: flex-end;

  @include respond-to(menu) {
    display: none;
  }
}

header .marquiz__container.topbar-button_mobile {
  @include respond-to(menu) {
    display: flex;
    justify-content: center;
  }
}

header .login_button {
  flex-shrink: 0;
  margin-right: 32px;

  @include respond-to(tablet-large) {
    margin-right: 8px;
  }

  @include respond-to(menu) {
    display: none;
  }
}

header .login_mobile_button {
  @include respond-to(menu) {
    display: flex;
    justify-content: center;
    z-index: 2;
    margin-top: 20px;
  }
}

@import './adaptive-style.scss';
@import './templates/mobile-menu/mobile-menu';
@import './src/ru/templates/features/features.scss';
@import './src/ru/templates/reviews/reviews.scss';
@import './src/ru/templates/platform-function/platform-function.scss';
@import './src/ru/solutions/templates/work/work.scss';
@import './src/ru/solutions/templates/developed/developed.scss';
@import './src/ru/solutions/templates/embed/embed.scss';
@import './src/ru/solutions/templates/integration/integration.scss';
@import './src/ru/templates/try-free/try-free.scss';
@import './src/ru/solutions/module/result/result.scss';
@import './src/ru/templates/contacts/contacts.scss';
@import './src/ru/templates/footer/footer.scss';
@import './src/ru/templates/cases/stats/stats.scss';
@import './src/ru/templates/form-modal/form-modal.scss';
