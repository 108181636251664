$topbar-height: 70px;
$container-width: 1160px;
$container-indent: 16px;

$font-family: 'Montserrat', sans-serif;

$ose-fill: #ff444a;
$black-50: rgba(0, 0, 0, 0.5);
$white: white;
$white-60: rgba(255, 255, 255, 0.6);
$green: #50db94;
$black-100: #000000;
$dark: #1d232a;
$black-20: rgba(0, 0, 0, 0.2);
$dark-rose: #e03c41;
$violet: #c863d0;
$purple: #4700bb;
$black-8: rgba(0, 0, 0, 0.08);
$black-70: rgba(0, 0, 0, 0.7);
$black-80: rgba(255, 255, 255, 0.8);
$battleship-grey: #727579;
$grey: #747474;
$gradient-green: linear-gradient(106.1deg, #00ae65 0.21%, #82d200 33.37%, #a1dd40 66.56%, #40c28c 100%);
$gradient-green-original: linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
$gradient-green-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
  linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
$gradient-orange: linear-gradient(106.1deg, #ffc000 0.21%, #e54300 33.37%, #eb7240 67.01%, #ffd040 100%);
$gradient-orange-original: linear-gradient(315.2deg, #e54300 0%, #ffc000 95.02%);
$gradient-orange-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
  linear-gradient(315.2deg, #e54300 0%, #ffc000 95.02%);
$gradient-red: linear-gradient(92.34deg, #ff511e 0.14%, #ff29ae 50%, #ff511e);

$gradient-white: linear-gradient(
  106.1deg,
  rgba(255, 255, 255, 0.1) 0.21%,
  rgba(255, 255, 255, 0.1) 33.37%,
  rgba(255, 255, 255, 0.2) 67.01%,
  rgba(255, 255, 255, 0.2) 100%
);
$gradient-grey: linear-gradient(
  106.1deg,
  rgba(224, 224, 224, 0.4) 0.21%,
  rgba(224, 224, 224, 0.4) 33.37%,
  rgba(224, 224, 224, 0.8) 67.01%,
  rgba(224, 224, 224, 0.8) 100%
);

@mixin h1 {
  font-size: 50px;
  line-height: 1.08;
  font-weight: 800;

  @include respond-to(tablet) {
    font-size: 42px;
  }

  @include respond-to(phone) {
    font-size: 28px;
  }
}

@mixin h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.1875;

  @include respond-to(tablet) {
    font-size: 24px;
  }

  @include respond-to(phone) {
    line-height: 1.12;
  }
}

@mixin h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;

  @include respond-to(tablet) {
    font-size: 20px;
  }

  @include respond-to(phone) {
    line-height: 1.11;
  }
}

@mixin h4 {
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
}

@mixin section_default_paddings {
  padding: 70px 0;
}

@mixin section_default_paddings_mobile {
  padding: 40px 0;
}

@mixin vertical_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 20px;
  max-width: 260px;
  p {
    text-align: center;
    transition: linear 250ms;
  }
  img,
  svg {
    margin-bottom: 24px;
  }
}

@mixin horizontal_card {
  display: flex;
  padding: 68px 80px 70px 60px;
  justify-content: space-between;
  .card-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 72px;

    &__title {
      @include h2();
      margin-bottom: 16px;
    }
  }
}

@mixin card_after_scale($scale) {
  $length: 30px;
  width: $length * $scale;
  height: $length * $scale;
  border-radius: $length * $scale;
  right: -($length * math.div($scale, 2));
  bottom: -($length * math.div($scale, 2));
}

@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@mixin swiper {
  .swiper-container {
    .swiper-wrapper {
      padding-bottom: 50px;
    }

    .swiper-pagination {
      &-bullet-active {
        background: #00ae65;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: calc(50% + 45px);
    z-index: 1;
    filter: hue-rotate(155deg) brightness(140%);

    &:hover {
      filter: hue-rotate(155deg) saturate(120%) brightness(160%);
    }

    &::after {
      content: '';
    }
  }
  .swiper-button-prev {
    left: 20px;
  }
  .swiper-button-next {
    transform: rotate(180deg);
    right: 20px;
  }
  .swiper-button-disabled {
    filter: saturate(0) opacity(0.5);

    &:hover {
      filter: saturate(0) opacity(0.5);
    }
  }
}

@mixin input-text($input-type) {
  width: 100%;
  border-radius: 10px;
  height: 56px;
  border: 1px solid transparent;
  transition: 0.2s all;
  @if $input-type == 'textarea' {
    padding-top: 16px;
    height: 104px;
  }
  #{$input-type} {
    padding-left: 16px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;

    &:-webkit-autofill {
      border-radius: 8px;
    }

    &:focus {
      outline: none;
    }

    @if $input-type == 'textarea' {
      resize: none;
    }
  }
  .input-message {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 12px 20px;
    right: 0;
    top: 70px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;

    &:after {
      display: block;
      position: absolute;
      top: -10px;
      right: 10px;
      content: '';
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid white;
    }
  }
  &.error {
    &:hover {
      .input-message {
        opacity: 1;
        visibility: visible;
      }
    }

    .input-message {
      animation-name: fade-in;
      animation-iteration-count: 2;
      animation-direction: alternate;
      animation-duration: 1s;
      display: block;
    }
  }
  &.dark {
    background-color: rgba(255, 255, 255, 0.1);

    &:focus-within {
      border-color: $white;
    }

    #{$input-type} {
      color: $white;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &.error {
      background-color: rgba(255, 0, 77, 0.2);
      border-color: #ffffff;
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: url('./assets/icons/ic24_alert.svg');
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;
      }
    }
  }
  &.light {
    background-color: rgba(116, 116, 116, 0.1);

    &:focus-within {
      border-color: $purple;
    }

    #{$input-type} {
      color: #181818;

      &::placeholder {
        color: rgba(24, 24, 24, 0.5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: #181818;
      }
    }

    &.error {
      background-color: rgba(255, 0, 77, 0.1);
      border-color: #ff004d;
      position: relative;
      display: flex;
      align-items: center;

      #{$input-type} {
        color: rgba(255, 0, 77, 0.5);
        -webkit-text-fill-color: rgba(255, 0, 77, 0.5);

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: rgba(255, 0, 77, 0.5);
        }
      }

      &:after {
        content: url(./assets/icons/ic24_alert_red.svg);
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;
      }
    }
  }
}

@mixin input-inner-text($input-type) {
  width: 100%;
  border-radius: 10px;
  height: 56px;
  border: 1px solid transparent;
  transition: 0.2s all;
  @if $input-type == 'textarea' {
    padding-top: 16px;
    height: 104px;
  }
  #{$input-type} {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;

    &:-webkit-autofill {
      border-radius: 8px;
    }

    &:focus {
      outline: none;
    }

    @if $input-type == 'textarea' {
      resize: none;
    }
  }
  .input-message {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 12px 20px;
    right: 0;
    top: 70px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;

    &:after {
      display: block;
      position: absolute;
      top: -10px;
      right: 10px;
      content: '';
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid white;
    }
  }
  &.error {
    &:hover {
      .input-message {
        opacity: 1;
        visibility: visible;
      }
    }

    .input-message {
      animation-name: fade-in;
      animation-iteration-count: 2;
      animation-direction: alternate;
      animation-duration: 1s;
      display: block;
    }
  }
  &.dark {
    background-color: rgba(255, 255, 255, 0.1);

    &:focus-within {
      border-color: $white;
    }

    #{$input-type} {
      color: $white;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &.error {
      background-color: rgba(255, 0, 77, 0.2);
      border-color: #ffffff;
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: url('../../../assets/icons/ic24_alert.svg');
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;
      }
    }
  }
  &.light {
    background-color: rgba(116, 116, 116, 0.1);

    &:focus-within {
      border-color: $purple;
    }

    #{$input-type} {
      color: #181818;

      &::placeholder {
        color: rgba(24, 24, 24, 0.5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: #181818;
      }
    }

    &.error {
      background-color: rgba(255, 0, 77, 0.1);
      border-color: #ff004d;
      position: relative;
      display: flex;
      align-items: center;

      #{$input-type} {
        color: rgba(255, 0, 77, 0.5);
        -webkit-text-fill-color: rgba(255, 0, 77, 0.5);

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: rgba(255, 0, 77, 0.5);
        }
      }

      &:after {
        content: url('../../../assets/icons/ic24_alert_red.svg');
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;
      }
    }
  }
}

// mockups mixins
@mixin mockup-no-shadow {
  position: absolute;
  filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.2));
  width: 60%;
  opacity: 0;
  transition: opacity linear 250ms;
  &--front {
    left: 0;
    bottom: 0;
    z-index: 3;
  }
  &--back {
    right: 0;
    top: 0;
    z-index: 1;
  }
  &.active {
    opacity: 1;
  }
}

@mixin screen-in-mockup {
  position: absolute;
  width: 51%;
  transition: opacity linear 250ms;
  opacity: 0;
  &--front {
    left: 4.3%;
    bottom: 2.6%;
    z-index: 4;
  }
  &--back {
    right: 4.5%;
    top: 2.5%;
    z-index: 2;
  }
  &.active {
    opacity: 1;
  }
}

// mockups mixins end

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@mixin background-image($img, $jpg-image: false, $retina: true) {
  background-repeat: no-repeat;

  @if $jpg-image == false {
    background-image: url(./assets/#{$img}.png);

    .webp & {
      background-image: url(./assets/#{$img}.webp);
    }

    @if $retina == true {
      @include media-retina {
        background-image: url(./assets/#{$img}@2x.png);

        .webp & {
          background-image: url(./assets/#{$img}@2x.webp);
        }
      }
    }
  } @else {
    background-image: url(./assets/#{$img}.jpg);

    .webp & {
      background-image: url(./assets/#{$img}.webp);
    }

    @if $retina == true {
      @include media-retina {
        background-image: url(./assets/#{$img}@2x.jpg);

        .webp & {
          background-image: url(./assets/#{$img}@2x.webp);
        }
      }
    }
  }
}

@mixin marquiz_button {
  background-image: linear-gradient(106.1deg, #ffc000 0.21%, #e54300 33.37%, #eb7240 67.01%, #ffd040 100%);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 12px 24px;
  color: #ffffff;
  outline: none;
  display: inline-block;
  -webkit-transition: linear 250ms;
  transition: linear 250ms;
  background-size: 300% 300%;

  @include respond-to(phone) {
    font-size: 14px;
  }
}
