@mixin media($expression, $media_type: 'only screen') {
  @media #{$media_type} and #{$expression} {
    @content;
  }
}

@mixin media-retina($expression: '') {
  @if $expression != '' {
    $expression: '' +
      '#{$expression} and (min-device-pixel-ratio: 2), '+'#{$expression} and (min-resolution: 192dpi), '+
      '#{$expression} and (min-resolution: 2dppx) ';

    @include media($expression: $expression) {
      @content;
    }
  } @else {
    $expression: '' +
      '#{$expression}(min-device-pixel-ratio: 2), '+'#{$expression}(min-resolution: 192dpi), '+
      '#{$expression}(min-resolution: 2dppx) ';

    @include media($expression: $expression) {
      @content;
    }
  }
}

@mixin respond-to($media: desktop, $retina: false, $prefix: '') {
  $sizes: (
    micro: '(max-width: 375px)',
    mini: '(max-width: 480px)',
    phone: '(max-width: 667px)',
    phone-large: '(max-width: 768px)',
    tablet-mini: '(max-width: 853px)',
    tablet-small: '(max-width: 932px)',
    tablet: '(max-width: 1024px)',
    menu: '(max-width: 1160px)',
    tablet-large: '(max-width: 1279px)',
    desktop-small: '(max-width: 1440px)',
    desktop-extra-plus: '(min-width: 1921px)',
  );
  $size: map-get($sizes, $media);
  $result: '';

  @if $prefix != '' {
    $result: '#{$prefix} and #{$size}';
  } @else {
    $result: $size;
  }

  @if $retina {
    @include media-retina($expression: $result) {
      @content;
    }
  } @else {
    @include media($expression: $result) {
      @content;
    }
  }
}
